// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `client-select2 div.controls {
  margin: 0.5em 0;
  display: flex;
  max-width: max-content;
  gap: 0.5em;
}
client-select2 div.controls button#control-delete-button,
client-select2 div.controls button#control-selection-button {
  border: 1px black solid;
  color: black;
  background-color: white;
}
client-select2 span.searchArea {
  z-index: 1;
  max-height: 35%;
  overflow-y: scroll;
  padding: 0 1em 1em 1em;
  margin: 0 1em 1em 1em;
  position: absolute;
  background-color: white;
  border-radius: 5%;
  border: 1px black solid;
}
client-select2 span.searchArea::-webkit-scrollbar {
  width: 10px;
}
client-select2 span.searchArea::-webkit-scrollbar-track {
  background: #f1f1f1;
}
client-select2 span.searchArea::-webkit-scrollbar-thumb {
  background: #888;
}
client-select2 span.searchArea::-webkit-scrollbar-thumb:hover {
  background: #555;
}
client-select2 span.searchArea input {
  margin-top: 0.5em;
}
client-select2 span.searchArea ul li {
  margin: 0.5em 0;
}
client-select2 span.searchArea ul li .additionalName {
  font-size: 90%;
  margin-left: 1em;
}
client-select2 span.searchArea ul li:hover {
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/client-select2.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;AAAJ;AALA;;EASM,uBAAA;EACA,YAAA;EACA,uBAAA;AAAN;AAXA;EAgBI,UAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;EACA,qBAAA;EACA,kBAAA;EACA,uBAAA;EACA,iBAAA;EACA,uBAAA;AAFJ;AAII;EACE,WAAA;AAFN;AAKI;EACE,mBAAA;AAHN;AAMI;EACE,gBAAA;AAJN;AAOI;EACE,gBAAA;AALN;AAlCA;EA2CM,iBAAA;AANN;AArCA;EAgDQ,eAAA;AARR;AAxCA;EAmDU,cAAA;EACA,gBAAA;AARV;AA5CA;EAyDQ,eAAA;AAVR","sourcesContent":["client-select2 {\n  div.controls {\n    margin: 0.5em 0;\n    display: flex;\n    max-width: max-content;\n    gap: 0.5em;\n\n    button#control-delete-button,\n    button#control-selection-button {\n      border: 1px black solid;\n      color: black;\n      background-color: white;\n    }\n  }\n\n  span.searchArea {\n    z-index: 1;\n    max-height: 35%;\n    overflow-y: scroll;\n    padding: 0 1em 1em 1em;\n    margin: 0 1em 1em 1em;\n    position: absolute;\n    background-color: white;\n    border-radius: 5%;\n    border: 1px black solid;\n\n    &::-webkit-scrollbar {\n      width: 10px;\n    }\n\n    &::-webkit-scrollbar-track {\n      background: #f1f1f1;\n    }\n\n    &::-webkit-scrollbar-thumb {\n      background: #888;\n    }\n\n    &::-webkit-scrollbar-thumb:hover {\n      background: #555;\n    }\n\n    input {\n      margin-top: 0.5em;\n    }\n\n    ul {\n      li {\n        margin: 0.5em 0;\n\n        .additionalName {\n          font-size: 90%;\n          margin-left: 1em;\n        }\n      }\n\n      li:hover {\n        cursor: pointer;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
