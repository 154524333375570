// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui-product-category-list-input input[type=checkbox]:indeterminate:before {
  display: inline-block;
  content: "\\2713";
  border: 0 !important;
  background-color: #CCC;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-product-category-list-input/component.less"],"names":[],"mappings":"AAGG;EACC,qBAAA;EACA,gBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;AAFJ","sourcesContent":[".ui-product-category-list-input {\n\tinput[type=checkbox] {\n\t\t&:indeterminate {\n\t\t\t&:before {\n\t\t\t\tdisplay: inline-block;\n\t\t\t\tcontent: \"\\2713\";\n\t\t\t\tborder: 0 !important;\n\t\t\t\tbackground-color: #CCC;\n\t\t\t\tcolor: #fff;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
