import { autoinject, computedFrom, TaskQueue } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { BusinessGroup, Client, MyHttpApi, OrderList, OrderMessage, OrderMessageAvailableInMessagesUpdate, OrderMessageGroup, OrderMessageGroupUpdateRequest, OrderMessageGroupWithPermission, OrderMessageUpdateRequest, PrivilegeItem, PrivilegeType, Product, ProductOrderMessageGroupWithPermission } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedClient, getSelectedCorporation, PosItemStub } from 'utils/corporation-util';
import { Corporation } from '../utils/api';

class UIOrderMessage {
	constructor(public orderMessage: OrderMessage, public canEdit: boolean, public orderList: OrderList | undefined, public orderMessageAvailableInMessagesUpdate: (req: OrderMessageAvailableInMessagesUpdate) => Promise<void>) {
	}

	get availableInMessages() {
		return this.orderMessage.availableInMessages;
	}
	set availableInMessages(availableInMessages: boolean) {
		this.orderMessage.availableInMessages = availableInMessages;
		void this.orderMessageAvailableInMessagesUpdate({ id: this.orderMessage.id, availableInMessages });
	}
}

interface UIProductOrderMessageGroupUpdateRequest {
	id?: string;
	products?: Product[];
	orderMessageGroupList: OrderMessageGroup[];
}

type OrderMessageGroupsByProduct = {
	[key in PrivilegeType]: {
		orderMessageGroupList: OrderMessageGroup[];
	}
};

@autoinject
export class OrderMessageList {
	itemSpecificRequest = this.newOrderMessageRequest(true);
	orderListSpecificRequest = this.newOrderMessageRequest(false);

	newOrderMessageRequest(itemSpecific: boolean): OrderMessageUpdateRequest {
		return {
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			availableInMessages: false,
			itemSpecific,
			message: "",
			delete: false
		};
	}

	private productOrderMessageGroupUpdateRequest: UIProductOrderMessageGroupUpdateRequest = {
		orderMessageGroupList: []
	};

	private privilege?: PrivilegeItem = undefined;
	private clientId?: number;
	private clients: { [key: number]: Client; } = {};
	private businessGroups: { [key: number]: BusinessGroup; } = {};
	private corporations: { [key: number]: Corporation; } = {};

	private productList: Product[] = [];
	private productMap: { [key: string]: Product; } = {};
	private productOrderMessageGroupList: ProductOrderMessageGroupWithPermission[] = [];
	private orderMessageGroupsByProduct?: { [key: string]: OrderMessageGroupsByProduct; } = {};
	private orderListList: OrderList[] = [];
	private orderListMap: { [key: string]: OrderList; } = {};

	private orderMessageGroupList: OrderMessageGroupWithPermission[] = [];
	private orderMessageGroupMap: { [key: string]: OrderMessageGroup; } = {};
	private orderMessageList: UIOrderMessage[] = [];
	private orderMessageGroupRequest: OrderMessageGroupUpdateRequest = {
		type: "CLIENT",
		name: "",
		orderMessages: [],
		delete: false,
	};
	productOrderMessageGroupProduct?: Product;
	productOrderMessageGroupOrderMessageGroup?: OrderMessageGroup[];
  isActive = false;

	constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N, private readonly taskQueue: TaskQueue) {
	}

	async activate() {
		this.privilege = getSelectedCorporation();
		let { clients, businessGroups, corporations } = await getAllAccessLevelsViaClient(this.api);
		this.clients = clients;
		this.businessGroups = businessGroups;
		this.corporations = corporations;
    this.taskQueue.queueTask(() => this.isActive = true);
	}

	getOwnerName(element: PosItemStub) {
		let name = "";
		if (element.clientId) {
			name = this.clients[element.clientId].nickname;
		}
		if (element.businessGroupId) {
			name = this.businessGroups[element.businessGroupId].name;
		}
		if (element.corporationId) {
			name = this.corporations[element.corporationId].name;
		}
		return name;
	}

	// * Order message
	async saveOrderMessage(itemSpecific: boolean) {
		let obj = itemSpecific ? { ...this.itemSpecificRequest, itemSpecific } : { ...this.orderListSpecificRequest, itemSpecific };
		obj = { ...obj, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT' };
		let om = await this.api.orderMessageUpdate(obj);
		let orderMessage = new UIOrderMessage(om, true, this.orderListMap[om.orderListId || ""], (args) => this.api.orderMessageAvailableInMessagesUpdate(args));
		this.orderMessageList.push(orderMessage);
		const newRequest = this.newOrderMessageRequest(itemSpecific);
		if (itemSpecific) {
			this.itemSpecificRequest = newRequest;
		} else {
			this.orderListSpecificRequest = newRequest;
		}
		await this.search();
	}

	// * Order Message Group
	async saveGroup(req: OrderMessageGroupUpdateRequest) {
		req = { ...req, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT' };
		await this.api.orderMessageGroupUpdate(req);
		this.orderMessageGroupRequest = {
			name: "",
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			orderMessages: [],
			delete: false,
		};
		await this.search();
	}

	async removeOrderMessageGroup(omg: OrderMessageGroupUpdateRequest) {
		await this.api.orderMessageGroupUpdate({ ...omg, delete: true });
		this.orderMessageGroupList = this.orderMessageGroupList.filter(x => x.orderMessageGroupExtended.id !== omg.id);
		this.productOrderMessageGroupList = this.productOrderMessageGroupList.filter(pomg => pomg.productOrderMessageGroup.orderMessageGroupId !== omg.id);
		await this.calculateProductOrderMessageGroups();
	}

	async deleteOrderMessage(row: UIOrderMessage) {
		await this.api.orderMessageUpdate({ ...row.orderMessage, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT', delete: true });
		await this.search();
	}

	@computedFrom("orderMessageGroupList.length")
	get dynamicOrderMessageGroup() {
		return this.orderMessageGroupList.map(omg => omg.orderMessageGroupExtended);
	}

	@computedFrom("orderMessageList.length")
	get itemSpecificOrderMessageList() {
		return this.orderMessageList.filter(x => x.orderMessage.itemSpecific);
	}

	@computedFrom("orderMessageList.length")
	get orderListSpecificOrderMessageList() {
		return this.orderMessageList.filter(x => !!x.orderMessage.orderListId);
	}

	@computedFrom("orderMessageList.length")
	get itemSpecificOrderMessageListForAssociate() {
		return this.orderMessageList.map(x => x.orderMessage).filter(x => x.itemSpecific);
	}

	newOrderMessageGroupsByProduct() {
		return {
			CORPORATION: {
				orderMessageGroupList: [],
			},
			BUSINESS_GROUP: {
				orderMessageGroupList: [],
			},
			CLIENT: {
				orderMessageGroupList: [],
			}
		};
	}

	/** Seems that the hashmap styled watching in Vue not fully working, so calculate again from server */
	async calculateProductOrderMessageGroups() {
		const productOrderMessageGroupList = await this.api.productOrderMessageGroupListWithPermissions({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
		this.productOrderMessageGroupList = productOrderMessageGroupList.filter(x => !x.productOrderMessageGroup.deleteTime);
		this.orderMessageGroupsByProduct = this.productOrderMessageGroupList.reduce((r, a) => {
			let specificness: PrivilegeType = "CLIENT";
			if (a.productOrderMessageGroup.businessGroupId) {
				specificness = "BUSINESS_GROUP";
			}
			else if (a.productOrderMessageGroup.corporationId) {
				specificness = "CORPORATION";
			}

			if (!r[a.productOrderMessageGroup.productId]) {
				r[a.productOrderMessageGroup.productId] = this.newOrderMessageGroupsByProduct();
			}

			const omg = this.orderMessageGroupMap[a.productOrderMessageGroup.orderMessageGroupId];
			r[a.productOrderMessageGroup.productId][specificness].orderMessageGroupList.push(omg);
			return r;
		}, Object.create(null));
	}

	productName(productId: string) {
		return this.productMap[productId]?.name;
	}

	@computedFrom("orderMessageGroupList.length")
	get orderMessageGroups(): OrderMessageGroup[] {
		return this.orderMessageGroupList.map(omg => { return omg.orderMessageGroupExtended; });
	}

	async saveProductOrderMessageGroups(productId: string) {
		if (!this.orderMessageGroupsByProduct || !this.privilege) {
			return;
		}
		const orderMessageGroupId = this.orderMessageGroupsByProduct[productId][this.privilege.type].orderMessageGroupList.map(omg => omg.id);
		await this.api.productOrderMessageGroupUpdateList({ productId, orderMessageGroupId, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT', delete: false });
		await this.calculateProductOrderMessageGroups();
	}

	@computedFrom("privilege.type")
	get isNotCorporationLevel() {
		return this.privilege?.type !== "CORPORATION";
	}

	@computedFrom("privilege.type")
	get isNotBusinessGroupLevel() {
		return this.privilege?.type !== "BUSINESS_GROUP";
	}

	async removeProductOrderMessageGroups(productId: string) {
		await this.api.productOrderMessageGroupUpdateList({ productId, orderMessageGroupId: [], privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT', delete: false });
		await this.calculateProductOrderMessageGroups();
	}

	async addProductOrderMessageGroups() {
		if (!this.productOrderMessageGroupUpdateRequest.products?.length) {
			return;
		}

		let productId = this.productOrderMessageGroupUpdateRequest.products[0].id;

		let productOrderMessageGroups = [...this.productOrderMessageGroupUpdateRequest.orderMessageGroupList];
		if (this.orderMessageGroupsByProduct) {
			const map = this.orderMessageGroupsByProduct[productId] || this.newOrderMessageGroupsByProduct();
			const type = this.privilege?.type || "CLIENT";
			const existingProductOrderMessageGroups = map[type].orderMessageGroupList;
			productOrderMessageGroups = [...existingProductOrderMessageGroups, ...productOrderMessageGroups];
		}
		const productOrderMessageGroupIds = productOrderMessageGroups.map(omg => omg.id);

		await this.api.productOrderMessageGroupUpdateList({
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			productId: productId,
			orderMessageGroupId: productOrderMessageGroupIds,
			delete: false,
		});
		await this.calculateProductOrderMessageGroups();
		this.productOrderMessageGroupUpdateRequest = {
			orderMessageGroupList: []
		};
	}

	async search() {
		if (!this.privilege) {
			return;
		}
		let [productList, orderListList, orderMessageGroupList, orderMessageListSS, productOrderMessageGroupList] = await Promise.all([
			this.api.productList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.orderListListWithDependencies({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.orderMessageGroupListWithPermission({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.orderMessageListWithPermissions({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productOrderMessageGroupListWithPermissions({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' })
		]);

		this.orderListMap = MyHttpApi.toHashStr(orderListList);
		this.productMap = MyHttpApi.toHashStr(productList);
		let orderMessageList = orderMessageListSS.filter(x => !x.orderMessage.open).map(x => new UIOrderMessage(x.orderMessage, x.canEdit, this.orderListMap[x.orderMessage.orderListId || ""], (args) => this.api.orderMessageAvailableInMessagesUpdate(args)));

		this.orderMessageGroupList = orderMessageGroupList.filter(x => !x.orderMessageGroupExtended.deleteTime && !x.orderMessageGroupExtended.supersededById);
		this.orderMessageGroupMap = MyHttpApi.toHashStr(this.orderMessageGroupList.map(omg => omg.orderMessageGroupExtended));
		this.productList = productList.filter(x => !x.deleteTime);
		this.orderListList = orderListList.filter(x => !x.deleteTime && !x.supersededById);
		this.orderMessageList = orderMessageList.filter(x => !x.orderMessage.deleteTime && !x.orderMessage.supersededById).sort((a: UIOrderMessage, b: UIOrderMessage) => {
			const key1 = `${a.orderMessage.clientId || 0}${a.orderMessage.businessGroupId || 0}${a.orderMessage.corporationId || 0}${a.orderMessage.message}`;
			const key2 = `${b.orderMessage.clientId || 0}${b.orderMessage.businessGroupId || 0}${b.orderMessage.corporationId || 0}${b.orderMessage.message}`;
			return key1.localeCompare(key2);
		});
		this.productOrderMessageGroupList = productOrderMessageGroupList.filter(x => !x.productOrderMessageGroup.deleteTime);
		this.clientId = getSelectedClient();
		await this.calculateProductOrderMessageGroups();
	}

	@computedFrom("router.currentInstruction.config.navModel.config.name")
	get editUrl() {
		let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
		return routeName.replace(/-list$/, "-edit");
	}

	@computedFrom("privilege.id", "isActive")
	get searchListener() {
		if (this.isActive) {
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			this.search().then(() => {
				this.productOrderMessageGroupUpdateRequest.products = [];
			});
		}
		return "";
	}
}
