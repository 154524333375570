import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { UIProductPricingGroup } from 'components/ui-product-layout-pricing-group/component';
import type { Location, PortionSize, Product, ProductCategory, ProductImage, ProductResponseWithPricingGroupRows, ProductSubCategory, ProductUpdateRequest, ProductUpdateRequestPP, SalesChannel, SalesRestriction, StorageUnit } from 'utils/api';
import { MyHttpApi, PrivilegeItem } from 'utils/api';
import { getPrivilege, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

class UIProductProduct {
	productResponse?: ProductResponseWithPricingGroupRows = undefined;

	@computedFrom("productProduct.total")
	get total() {
		return this.productProduct.total ?? Number.NaN;
	}

	@computedFrom("portionSizeList.length")
	get productPortionSizeList() {
		const portionSizeList = this.portionSizeList.filter(x => !x.deleteTime && !x.supersededById);
		if (!this.productResponse) {
			return portionSizeList;
		}
		return portionSizeList.filter(ps => this.productResponse!.productPricingGroupList.map(ppg => ppg.portionSizeId).includes(ps.id) || this.productResponse!.pricingGroupRowList.map(x => x.portionSizeId).includes(ps.id));
	}

	updateTotal() {
		if (!this.productProduct.useSlavePrice) {
			this.productProduct.productPricingGroupId = undefined; // generated from next()
		}
		if ((this.portionSizeDisabled && !this.productProduct.productPricingGroupId) || this.openPrice) {
			this.productProduct.portionSizeId = undefined; // generated from next()
		}
		if (this.productProduct.useSlavePrice && !this.productProduct.productPricingGroupId) {
			this.productProduct.total = undefined; // generated from next()
		}
		if (this.productProduct.useSlavePrice && this.productResponse) {
			const pg = this.productResponse.productPricingGroupList.find(x => x.id === this.productProduct.productPricingGroupId);
			const price = pg?.price;
			const portionSizeId = pg?.portionSizeId;
			if (price) {
				this.productProduct.total = (this.productProduct.amount || 1) * price;
			}
			this.productProduct.portionSizeId = portionSizeId;
		}
	}

	onPortionChange() {
		let pricingGroups = this.productResponse?.productPricingGroupList.filter(pg => pg.portionSizeId === this.productProduct.portionSizeId);
		if (pricingGroups?.length == 1) {
			const pg = pricingGroups[0];
			{
				const price = pg.price;
				if (price && !this.productProduct.total) {
					this.productProduct.total = (this.productProduct.amount || 1) * price;
				}
			}
		}
	}

	@computedFrom("productProduct.useSlavePrice", "productResponse.product.type")
	get portionSizeDisabled() {
		return this.productProduct.useSlavePrice || this.productResponse?.product.type === "ALIAS";
	}

	@computedFrom("productProduct.useSlavePrice", "productResponse.product.type", "openPrice")
	get pricingGroupDisabled() {
		return this.openPrice || !this.productProduct.useSlavePrice || this.productResponse?.product.type === "ALIAS";
	}

	@computedFrom("productResponse.product.openPrice")
	get openPrice() {
		return this.productResponse?.product.openPrice;
	}

	selectProductPricingGroup(ppc?: UIProductPricingGroup) {
		this.productProduct.productPricingGroupId = ppc?.id;
	}

	private readonly observableProperties = ["amount", "productPricingGroupId", "useSlavePrice"];

	constructor(
		public productResponseList: ProductResponseWithPricingGroupRows[],
		public portionSizeList: PortionSize[],
		public salesChannelList: SalesChannel[],
		public salesRestrictionList: SalesRestriction[],
		public productProduct: ProductUpdateRequestPP,
		bindingEngine: BindingEngine,
		public extraPortionSize?: PortionSize,
	) {
		this.productResponse = productResponseList.find(pr => pr.product.id === this.productProduct.productId);
		this.observableProperties.forEach(p => {
			bindingEngine.propertyObserver(this.productProduct, p).subscribe(()=>{
				this.updateTotal();
			});
		})
	}
}

@autoinject
export class PosRecipeEdit {
	imageBlob?: string = undefined;
	image?: ProductImage = undefined;
	newProduct?: Product;
	productResponseList: ProductResponseWithPricingGroupRows[] = [];
	productCategoryList: ProductCategory[] = [];
	productSubCategoryList: ProductSubCategory[] = [];
	portionSizeList: PortionSize[] = [];
	salesChannelList: SalesChannel[] = [];
	fullSalesChannelList: SalesChannel[] = [];
	fullStorageUnitList: StorageUnit[] = [];
	salesRestrictionList: SalesRestriction[] = [];
	regularProductList: Product[] = [];
	product: ProductUpdateRequest = {
		abbreviation: "",
		baseUnit: "PCS",
		doNotAllowComponentsToMerge: false,
		ean: [],
		locationId: undefined,
		name: "",
		useSlavePortion: false,
		useSlavePrice: false,
		openName: false,
		openPrice: false,
		productPricingGroupList: [],
		productProductList: [],
		productCategoryId: "",
		useSalesChannel: false,
		productType: "RECIPE",
		useChosenProductOnBill: false,
		useChosenProductOnOrderList: false,
		useChosenProductOnPos: false,
		warehousedOnly: false,
		delete: false,
		type: 'CLIENT'
	};
	private activeTab = "BASIC";
	productProduct: UIProductProduct[] = [];
	locationList: Location[] = [];
	location?: Location = undefined;
	locationChoices: { id: string, name: string; }[] = [];
	eanInput = "";
	productLocationId? = "";
	fullPortionSizeList: PortionSize[] = [];
	extraProductCategory?: ProductCategory = undefined;
	extraProductSubCategory?: ProductSubCategory = undefined;
	extraSalesChannel?: SalesChannel = undefined;
	extraSalesRestriction?: SalesRestriction = undefined;
	private privilege?: PrivilegeItem;
  private typeAndName = "";
	total?: number = 0;
  private disabled = false;

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly bindingEngine: BindingEngine, private readonly i18n: I18N, private readonly notify: Notify) {
	}

  // ! FIXME - separate list dynamic + observer logic
  @computedFrom("product.productCategoryId", "productCategoryList", "productSubCategoryList")
	get productSubCategoryListDynamic() {
		let productCategoryId = this.product.productCategoryId;
		const productCategory = this.productCategoryList.find(x => x.id == productCategoryId);
		const productSubCategory = this.productSubCategoryList.find(x => x.id == this.product.productSubCategoryId);
		if (this.product.productSubCategoryId && productSubCategory?.productCategoryId !== productCategoryId) {
			this.product.productSubCategoryId = undefined;
			this.extraProductSubCategory = undefined;
		}
		if (productCategory) {
			this.product.baseUnit = productSubCategory?.defaultBaseUnit || productCategory.defaultBaseUnit;
			this.product.storageUnitId = productSubCategory?.defaultStorageUnitId || productCategory.defaultStorageUnitId;
		}
		return this.productSubCategoryList.filter(pc => productCategoryId != undefined && pc.productCategoryId === productCategoryId);
	}

	/* Automatically fill in abbreviation. If the name is 1 char longer or shorter and shares a common root, then
	 * overwrite abbreviation. */
	// ! do not compute from abbreviation, this breaks the logic
	@computedFrom("product.name")
	get nameObserver() {
		let name = this.product.name || "";
		let abbreviation = this.product.abbreviation || "";
		if (Math.abs(name.length - abbreviation.length) <= 1 && name.substring(0, Math.min(name.length, abbreviation.length)) === abbreviation.substring(0, Math.min(name.length, abbreviation.length))) {
			this.product.abbreviation = name.substring(0, 40);
		}
		return "";
	}

	@computedFrom("productProduct.length")
	get productProductObserver() {
		this.productProduct.forEach(productProduct => productProduct.updateTotal());
		return "";
	}

  @computedFrom("productProduct")
	get totalPrice() {
		let total = this.productProduct.map(x => x.total).reduce((a: number, b: number) => {return a * 1 + (b || 0) * 1}, 0);
		return !isNaN(total) ? total : 0;
	}

	async activate(params: { id?: string }) {
		this.privilege = getPrivilege();
		if (params.id) {
			let [product, image] = await Promise.all([this.api.productByIdExtended({ id: params.id }), this.api.productImageById({ id: params.id })]);
			this.product = {
				...product.product,
				productPricingGroupList: [],
				productProductList: product.productProductList,
				delete: !!product.product.deleteTime,
				type: this.privilege?.type || 'CLIENT',
				productType: product.product.type,
			};
			this.privilege = privilegeItemFromElement(product.product);
			this.product.image = image?.imageData;
			this.imageBlob = image?.imageData && "data:" + image.imageMimeType + ";base64," + image.imageData;
      this.productLocationId = product.product.locationId;
		}
    let [productCategoryList, productSubCategoryList, portionSizeList, salesChannelList, storageUnitList, salesRestrictionList, productResponseList] = await Promise.all([
			this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.portionSizeList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesChannelList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.storageUnitList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.salesRestrictionList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productListWithPricingGroupRows({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
		]);

    this.extraProductCategory = productCategoryList.find(x => x.id === this.product.productCategoryId);
    this.extraProductSubCategory = productSubCategoryList.find(x => x.id === this.product.productSubCategoryId);
    this.extraSalesChannel = salesChannelList.find(x => x.id === this.product.salesChannelId);
    this.extraSalesRestriction = salesRestrictionList.find(x => x.id === this.product.productSubCategoryId);
    
    this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);

		this.productResponseList = productResponseList;
		this.productCategoryList = productCategoryList.filter(x => !x.deleteTime && !x.supersededById);
		this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime && !x.supersededById);
		this.fullPortionSizeList = portionSizeList;
		this.portionSizeList = this.fullPortionSizeList.filter(x => !x.deleteTime && !x.supersededById);
		this.fullSalesChannelList = salesChannelList;
		this.fullStorageUnitList = storageUnitList;
		this.salesChannelList = this.fullSalesChannelList.filter(x => !x.deleteTime && !x.supersededById);
		this.salesRestrictionList = salesRestrictionList.filter(x => !x.deleteTime && !x.supersededById);

		let productProduct = this.product.productProductList.map(x => new UIProductProduct(productResponseList, portionSizeList, salesChannelList, salesRestrictionList, x, this.bindingEngine, portionSizeList.find(ps => ps.id === x.portionSizeId)));
		this.productProduct = productProduct;

    let locationMap: { [key: string]: Location; } = {};

    if (this.privilege?.id) {
      let canEdit = await this.api.privilegeCanEdit(this.privilege);
      if (this.privilege.type === 'CLIENT') {
        const [locationList, bgr]  = await Promise.all([
          this.api.locationList({ id: this.privilege.id }),
          this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id })
        ]);
        this.locationList = locationList.filter(x => !x.deleteTime && (!this.productLocationId || this.productLocationId === x.id));
        locationMap = MyHttpApi.toHashStr(locationList);
        canEdit = bgr.clientSpecificRecipes;
      }
      this.disabled = !canEdit;
    }

    let regularProductList = productResponseList.map(pr => pr.product).filter(p => p.type === "REGULAR" || p.type === "ALIAS");
    regularProductList.forEach(p => p.locationId && (p.name += " / " + locationMap[p.locationId]?.name));
    // * Should really be computed, as we now can face a situation, where main-product location-id changes, and we already have bad location data on rows?
		this.regularProductList = regularProductList.filter(x => !x.deleteTime && !x.supersededById && (!x.locationId || x.locationId === this.product.locationId));
	}

  @computedFrom("privilege.type", "product.id", "productLocationId", "locationList.length")
  get canModifyLocation() {
		return !!(this.privilege?.type === 'CLIENT' && ((!this.product.id && this.locationList.length > 1) || this.productLocationId));
	}

  @computedFrom("disabled")
  get disabledText() {
    if (this.disabled) {
      return this.i18n.tr('businessGroupRestriction.readOnly');
    }
    return "";
  }

	_buildRequest(): ProductUpdateRequest | undefined {
		let obj = { ...this.product };
		obj.productProductList = this.productProduct.map(x => ({ ...x.productProduct }));
		if (!obj.productProductList.length) {
			this.notify.info("product.noSubProduct", {});
			return undefined;
		}
		return obj;
	}

	selectTab(tabName: string) {
		// check that all required fields are filled in before allowing to change the tab
		if (document.querySelector("form")?.reportValidity()) {
			this.activeTab = tabName;
		}
	}

	addEan() {
		if (this.eanInput.length) {
			this.product.ean.push(this.eanInput);
		}
		this.eanInput = "";
	}

	deleteEan(idx: number) {
		this.product.ean.splice(idx, 1);
	}

	async save(deleted: boolean) {
		// HAx0r, if we have focus in eanInput-field, means we want to just add ean and no submit...
		let focused = document.activeElement;
		if (focused?.id == "eanInput") {
			this.addEan();
			return;
		}
		let obj = this._buildRequest();
		if (!obj) {
			return;
		}
		obj.delete = deleted;
		await this.api.productUpdate({
			...obj,
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			delete: deleted
		});
		this.router.navigateBack();
	}

	moveUp(pp: UIProductProduct) {
		let list = this.productProduct;
		let idx = list.indexOf(pp);
		if (idx == 0) {
			return;
		}
		// stupid ember doesn't observe item mutation apparently, even when we do it with get/set
		let tmp = list[idx - 1];
		list[idx - 1] = (list[idx]);
		list[idx] = tmp;
		this.productProduct = [...list];
	}

	moveDown(pp: UIProductProduct) {
		let list = this.productProduct;
		let idx = list.indexOf(pp);
		if (idx == list.length - 1) {
			return;
		}
		let tmp = list[idx + 1];
		list[idx + 1] = (list[idx]);
		list[idx] = tmp;
		this.productProduct = [...list];
	}

	addReferencedProduct() {
		if (this.newProduct) {
			this.productProduct.push(new UIProductProduct(
				this.productResponseList,
				this.portionSizeList,
				this.salesChannelList,
				this.salesRestrictionList,
				{
					useSlavePrice: false,
					amount: 1,
					productId: this.newProduct.id,
				},
				this.bindingEngine
			));
			this.newProduct = undefined;
		}
	}

	removeReferencedRow(pp: UIProductProduct) {
		const index = this.productProduct.indexOf(pp);
		if (index !== -1) {
			this.productProduct.splice(index, 1);
		}
	}

	clearImage() {
		this.product.image = undefined;
		this.imageBlob = undefined;
	}

	async productImageOnLoadHandler(event: Event) {
		const inputElement = event.target;
		const files = (<HTMLInputElement>inputElement).files;
		if (!files) {
			return;
		}
		const file = files[0];
		if (!file) {
			return;
		}
		const reader = new FileReader();
		reader.onload = async () => {
			const res = reader.result;
			if (typeof res === "string") {
				const imageData = res.split(",", 2)[1];
				let imageIsOk = await this.api.imageTest({ image: imageData });
				if (!imageIsOk) {
					this.product.image = undefined;
					this.notify.info("admin.imageTestNotOk", {});
					return;
				}
				this.imageBlob = res;
				this.product.image = imageData;
			}
		};
		reader.readAsDataURL(file);
	}
}
