import { autoinject, bindable, bindingMode, computedFrom, TaskQueue } from 'aurelia-framework';
import { ProductCategory, ProductSubCategory } from 'utils/api';

@autoinject
export class UiProductCategoryListInputCustomElement {
	@bindable({ defaultBindingMode: bindingMode.toView }) productCategoryList!: ProductCategory[];
	@bindable({ defaultBindingMode: bindingMode.toView }) productSubCategoryList!: ProductSubCategory[];
	@bindable({ defaultBindingMode: bindingMode.twoWay }) productCategory: { [key: string]: boolean; } = {};
	@bindable({ defaultBindingMode: bindingMode.twoWay }) productSubCategory: { [key: string]: boolean | null; } = {};
	@bindable({ defaultBindingMode: bindingMode.twoWay }) productCategoryId: string[] = [];
	@bindable({ defaultBindingMode: bindingMode.twoWay }) productSubCategoryId: string[] = [];
	@bindable({ defaultBindingMode: bindingMode.toView }) disabled: boolean = false;

	private productSubCategoryListByProductCategoryId: { [key: string]: ProductSubCategory[]; } = {};

	constructor(private element: Element, private readonly taskQueue: TaskQueue) {}

	@computedFrom("productSubCategoryList")
	get productSubCategoryListObserver() {
		this.productSubCategoryListByProductCategoryId = {};
		for (let psc of this.productSubCategoryList) {
			if (!(psc.productCategoryId in this.productSubCategoryListByProductCategoryId)) {
				this.productSubCategoryListByProductCategoryId[psc.productCategoryId] = [];
			}
			this.productSubCategoryListByProductCategoryId[psc.productCategoryId].push(psc);
		}
		return "";
	}

	productCategoryClicked(pc: ProductCategory) {
		for (let psc of this.productSubCategoryListByProductCategoryId[pc.id] || []) {
			this.productSubCategory[psc.id] = !this.productCategory[pc.id] ? null : false;
		}
		return true;
	}

	productSubCategoryClicked(pc: ProductCategory) {
		this.taskQueue.queueTask(() => {
			const subCategories = this.productSubCategoryList.filter(psc => psc.productCategoryId === pc.id);
			const selectedSubCategories = subCategories.filter(sc => this.productSubCategory[sc.id]);
			if(selectedSubCategories.length == subCategories.length) {
				subCategories.forEach(sc => this.productSubCategory[sc.id] = null);
				return;
			}

			subCategories.forEach(sc => {
				if(this.productSubCategory[sc.id] == null) {
					this.productSubCategory[sc.id] = false;
				}
			});

			this.productCategory[pc.id] = selectedSubCategories.length != 0;
		});
		return true;
	}
}
